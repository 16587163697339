export var TrackingStatusEnum;
(function (TrackingStatusEnum) {
    TrackingStatusEnum["FOUND"] = "FOUND";
    TrackingStatusEnum["NOTFOUND"] = "NOT_FOUND";
})(TrackingStatusEnum || (TrackingStatusEnum = {}));
export var CarriersEnum;
(function (CarriersEnum) {
    CarriersEnum["USPS"] = "USPS";
    CarriersEnum["UDS"] = "UDS";
    CarriersEnum["CDL"] = "CDL";
    CarriersEnum["LSO"] = "LSO";
    CarriersEnum["ONTRAC"] = "ONTRAC";
})(CarriersEnum || (CarriersEnum = {}));
export var TrackingEventEventTypeEnum;
(function (TrackingEventEventTypeEnum) {
    TrackingEventEventTypeEnum["MANIFESTEDINBOUND"] = "MANIFESTED_INBOUND";
    TrackingEventEventTypeEnum["ARRIVED"] = "ARRIVED";
    TrackingEventEventTypeEnum["SCANNED"] = "SCANNED";
    TrackingEventEventTypeEnum["ZONE"] = "ZONE";
    TrackingEventEventTypeEnum["CONTAINERIZED"] = "CONTAINERIZED";
    TrackingEventEventTypeEnum["TMPCONTAINERIZED"] = "TMP_CONTAINERIZED";
    TrackingEventEventTypeEnum["MOVED"] = "MOVED";
    TrackingEventEventTypeEnum["PRIMARY"] = "PRIMARY";
    TrackingEventEventTypeEnum["SECONDARY"] = "SECONDARY";
    TrackingEventEventTypeEnum["STAGED"] = "STAGED";
    TrackingEventEventTypeEnum["LOADED"] = "LOADED";
    TrackingEventEventTypeEnum["OPENED"] = "OPENED";
    TrackingEventEventTypeEnum["CLOSED"] = "CLOSED";
    TrackingEventEventTypeEnum["DISPATCHED"] = "DISPATCHED";
    TrackingEventEventTypeEnum["MANIFESTEDOUTBOUND"] = "MANIFESTED_OUTBOUND";
    TrackingEventEventTypeEnum["EXCEPTIONMISSORT"] = "EXCEPTION_MISSORT";
    TrackingEventEventTypeEnum["EXCEPTIONMISSORTFORCED"] = "EXCEPTION_MISSORT_FORCED";
    TrackingEventEventTypeEnum["EXCEPTIONMISLOAD"] = "EXCEPTION_MISLOAD";
    TrackingEventEventTypeEnum["EXCEPTIONMISLOADFORCED"] = "EXCEPTION_MISLOAD_FORCED";
    TrackingEventEventTypeEnum["EXCEPTIONMISMOVE"] = "EXCEPTION_MISMOVE";
    TrackingEventEventTypeEnum["EXCEPTIONMISMOVEFORCED"] = "EXCEPTION_MISMOVE_FORCED";
    TrackingEventEventTypeEnum["EXCEPTIONPRINTFORCED"] = "EXCEPTION_PRINT_FORCED";
    TrackingEventEventTypeEnum["EXCEPTIONDISPATCHFORCED"] = "EXCEPTION_DISPATCH_FORCED";
    TrackingEventEventTypeEnum["EXCEPTIONUNIDENTIFIED"] = "EXCEPTION_UNIDENTIFIED";
    TrackingEventEventTypeEnum["EXCEPTIONUNPROCESSABLE"] = "EXCEPTION_UNPROCESSABLE";
    TrackingEventEventTypeEnum["EXCEPTIONTROUBLESHOOT"] = "EXCEPTION_TROUBLESHOOT";
    TrackingEventEventTypeEnum["EXCEPTIONRELABELED"] = "EXCEPTION_RELABELED";
    TrackingEventEventTypeEnum["EXCEPTIONRETURNED"] = "EXCEPTION_RETURNED";
    TrackingEventEventTypeEnum["EXCEPTIONMISSINGDISPATCHINFO"] = "EXCEPTION_MISSING_DISPATCH_INFO";
    TrackingEventEventTypeEnum["CARRIERARRIVEDATFACILITY"] = "CARRIER_ARRIVED_AT_FACILITY";
    TrackingEventEventTypeEnum["CARRIEROUTFORDELIVERY"] = "CARRIER_OUT_FOR_DELIVERY";
    TrackingEventEventTypeEnum["CARRIERDELIVERED"] = "CARRIER_DELIVERED";
    TrackingEventEventTypeEnum["CARRIERDELIVERYFAILED"] = "CARRIER_DELIVERY_FAILED";
    TrackingEventEventTypeEnum["CARRIERINTRANSIT"] = "CARRIER_IN_TRANSIT";
    TrackingEventEventTypeEnum["GROUPED"] = "GROUPED";
    TrackingEventEventTypeEnum["UNGROUPED"] = "UNGROUPED";
    TrackingEventEventTypeEnum["PLANNED"] = "PLANNED";
    TrackingEventEventTypeEnum["UNLOADING"] = "UNLOADING";
    TrackingEventEventTypeEnum["FINISHED"] = "FINISHED";
})(TrackingEventEventTypeEnum || (TrackingEventEventTypeEnum = {}));
export var TrackingEventEventSourceEnum;
(function (TrackingEventEventSourceEnum) {
    TrackingEventEventSourceEnum["SYSTEM"] = "SYSTEM";
    TrackingEventEventSourceEnum["LINEHAUL"] = "LINEHAUL";
    TrackingEventEventSourceEnum["CARRIER"] = "CARRIER";
})(TrackingEventEventSourceEnum || (TrackingEventEventSourceEnum = {}));
