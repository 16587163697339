<svg
  width="229"
  height="37"
  viewBox="0 0 229 37"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_270_22629)">
    <path
      d="M45.1166 36.4169C41.0253 36.4169 38.3038 33.8014 37.7737 33.236L40.1418 30.9828C40.3803 31.239 42.5806 33.4392 45.7613 33.1211C45.7969 33.1211 49.5258 32.6793 51.0014 29.5867C51.6551 28.2259 51.7345 26.6708 51.2485 24.9566C50.8422 23.534 50.1001 22.3676 49.0308 21.5105C48.5096 21.0864 47.396 20.3442 45.8145 20.1321C44.5066 19.9554 43.0928 20.2205 41.5027 20.9539C39.6116 21.8198 38.8252 22.7123 37.0933 24.665C36.245 25.6193 35.1936 26.8122 33.7356 28.2878C30.5105 31.5571 28.5577 33.5364 25.2 34.9501C22.1339 36.2402 19.3329 36.4611 17.5304 36.4258C13.996 36.3462 11.3098 35.2682 10.0197 34.6321C5.0274 32.158 1.28977 27.201 0.273622 21.6784C-0.0621468 19.8671 -0.822044 13.4786 3.48993 7.70868C6.04354 4.28914 9.8872 1.87691 14.2964 0.922618C18.0605 0.109704 21.2415 0.692882 22.7966 1.10818C26.5343 2.10664 29.079 4.08592 30.2631 5.1816C31.8713 6.66603 33.1701 8.42438 34.1332 10.4125L31.1909 11.8351C30.4133 10.2181 29.353 8.79551 28.0452 7.58497C27.0821 6.70137 25.0145 5.0844 21.9572 4.27147C20.676 3.9357 18.0782 3.45855 14.9856 4.13009C11.2921 4.92535 8.22604 6.84275 6.1054 9.6791C2.58866 14.3799 3.21602 19.6108 3.48993 21.0952C4.32053 25.6193 7.37779 29.675 11.4689 31.6808C12.5204 32.1933 14.7117 33.0681 17.6187 33.1388C19.7217 33.1829 21.9661 32.7588 23.9365 31.9194C26.5785 30.806 28.1424 29.2863 31.4118 25.9727C32.8079 24.559 33.824 23.4103 34.6457 22.4825C36.4394 20.4591 37.6146 19.1248 40.1418 17.9673C42.28 16.9865 44.3389 16.6154 46.2563 16.8805C48.6241 17.2074 50.2945 18.3207 51.0807 18.9569C52.6799 20.247 53.7934 21.97 54.3854 24.0553C55.269 27.1656 54.6239 29.5336 53.935 30.9828C51.6641 35.7454 46.3005 36.3374 46.0706 36.3639C45.7437 36.3992 45.4169 36.4169 45.0986 36.4169H45.1166Z"
      fill="white"
    />
    <path
      d="M68.8893 19.7512C68.8893 15.3796 72.1497 12.391 76.2496 12.391C79.2381 12.391 81.0164 13.873 82.0784 15.6019L79.9544 16.6886C79.263 15.5031 77.9044 14.6139 76.2742 14.6139C73.5574 14.6139 71.4829 16.7627 71.4829 19.7512C71.4829 22.7398 73.582 24.8885 76.2742 24.8885C77.929 24.8885 79.2876 24.0241 79.9544 22.8139L82.0784 23.9006C80.9918 25.6048 79.2381 27.1114 76.2496 27.1114C72.1497 27.1114 68.8893 24.1229 68.8893 19.7512Z"
      fill="white"
    />
    <path
      d="M87.2648 26.8398V12.6133H89.7595V24.6416H96.0329V26.8398H87.2648Z"
      fill="white"
    />
    <path
      d="M107.493 12.3911C111.766 12.3911 114.779 15.5032 114.779 19.7513C114.779 23.9995 111.766 27.1115 107.493 27.1115C103.22 27.1115 100.207 23.9995 100.207 19.7513C100.207 15.5032 103.22 12.3911 107.493 12.3911ZM107.493 14.614C104.603 14.614 102.775 16.8122 102.775 19.7513C102.775 22.6905 104.603 24.8887 107.493 24.8887C110.383 24.8887 112.211 22.6658 112.211 19.7513C112.211 16.8369 110.358 14.614 107.493 14.614Z"
      fill="white"
    />
    <path
      d="M120.362 12.6133H122.881V21.1344C122.881 23.4066 124.14 24.8639 126.536 24.8639C128.932 24.8639 130.167 23.382 130.167 21.1344V12.6133H132.686V21.2085C132.686 24.7404 130.661 27.0867 126.536 27.0867C122.411 27.0867 120.362 24.7157 120.362 21.2332V12.6133Z"
      fill="white"
    />
    <path
      d="M138.886 26.8398V12.6133H144.171C148.617 12.6133 151.605 15.5525 151.605 19.7265C151.605 23.9006 148.592 26.8398 144.171 26.8398H138.886ZM141.38 24.6416H144.171C147.258 24.6416 149.061 22.4187 149.061 19.7265C149.061 17.0344 147.357 14.7868 144.171 14.7868H141.38V24.6169V24.6416Z"
      fill="white"
    />
    <path
      d="M157.705 22.9126C158.693 23.95 160.225 24.8638 162.2 24.8638C164.176 24.8638 165.066 23.8512 165.066 22.9126C165.066 21.6283 163.559 21.2578 161.83 20.7885C159.509 20.2205 156.767 19.5289 156.767 16.5157C156.767 14.1693 158.841 12.3663 161.954 12.3663C164.176 12.3663 165.955 13.0579 167.289 14.3422L165.856 16.1946C164.745 15.0832 163.238 14.5645 161.731 14.5645C160.225 14.5645 159.311 15.256 159.311 16.3428C159.311 17.4295 160.768 17.8247 162.448 18.2446C164.794 18.8373 167.585 19.5783 167.585 22.6657C167.585 25.0367 165.93 27.062 162.077 27.062C159.459 27.062 157.557 26.1482 156.298 24.7897L157.681 22.8632L157.705 22.9126Z"
      fill="white"
    />
    <path
      d="M179.712 12.3911C183.985 12.3911 186.998 15.5032 186.998 19.7513C186.998 23.9995 183.985 27.1115 179.712 27.1115C175.439 27.1115 172.451 23.9995 172.451 19.7513C172.451 15.5032 175.464 12.3911 179.712 12.3911ZM179.712 14.614C176.822 14.614 174.994 16.8122 174.994 19.7513C174.994 22.6905 176.822 24.8887 179.712 24.8887C182.602 24.8887 184.429 22.6658 184.429 19.7513C184.429 16.8369 182.577 14.614 179.712 14.614Z"
      fill="white"
    />
    <path
      d="M200.731 26.8398L197.57 21.5296H195.1V26.8398H192.605V12.6133H198.854C201.67 12.6133 203.497 14.441 203.497 17.0591C203.497 19.6772 201.842 20.9615 200.138 21.2332L203.621 26.8151H200.731V26.8398ZM198.508 14.8115H195.1V19.3314H198.508C199.916 19.3314 200.929 18.4422 200.929 17.0591C200.929 15.676 199.916 14.7868 198.508 14.7868V14.8115Z"
      fill="white"
    />
    <path
      d="M212.364 26.8398V14.8115H208.067V12.6133H219.181V14.8115H214.883V26.8398H212.389H212.364Z"
      fill="white"
    />
    <path
      d="M225.034 18.5478C226.694 18.5478 228.016 17.2113 228.016 15.5653C228.016 13.9053 226.694 12.5829 225.034 12.5829C223.387 12.5829 222.051 13.9053 222.051 15.5653C222.051 17.2113 223.387 18.5478 225.034 18.5478ZM225.034 13.019C226.44 13.019 227.58 14.1445 227.58 15.5653C227.58 16.9721 226.44 18.1257 225.034 18.1257C223.613 18.1257 222.473 16.9721 222.473 15.5653C222.473 14.1445 223.613 13.019 225.034 13.019ZM226.426 17.2817L225.512 15.9171C225.709 15.9171 226.384 15.6638 226.384 14.9041C226.384 14.2711 225.864 13.8631 225.287 13.8631H223.894V17.2817H224.358V15.9311H224.963L225.85 17.2817H226.426ZM225.287 14.2711C225.582 14.2711 225.906 14.5243 225.906 14.9041C225.906 15.2558 225.582 15.5091 225.287 15.5091H224.358V14.2711H225.287Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_270_22629">
      <rect
        width="228.682"
        height="36"
        fill="white"
        transform="translate(0 0.514404)"
      />
    </clipPath>
  </defs>
</svg>
