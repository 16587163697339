<script lang="ts">
  export let title: string;
  export let margin: string = "0";
</script>

<span class="tooltip-wrapper">
  <div class="tooltip" data-text={title} style="margin:{margin}"><slot /></div>
</span>

<style>
  .tooltip {
    display: inline;
    position: relative;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25;
    font-weight: 400;
  }

  .tooltip:hover:after {
    background: var(--color-cypress);
    border-radius: var(--radius-small);
    left: -4rem;
    right: -4rem;
    top: 1.5rem;
    color: var(--color-white);
    content: attr(data-text);
    display: block;
    padding: 0.5rem;
    position: absolute;
    z-index: 10;
  }

  .tooltip:hover:before {
    border: solid;
    border-color: var(--color-cypress) transparent;
    border-width: 0 0.5rem 0.5rem;
    left: calc(50% - 0.5rem);
    bottom: -0.5rem;
    content: "";
    display: block;
    position: absolute;
    z-index: 11;
  }

  @media (max-width: 640px) {
    .tooltip:hover:before,
    .tooltip:hover:after {
      display: none;
    }
  }
</style>
