<svg
  width="10"
  height="11"
  viewBox="0 0 10 11"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.90723 1.38867L0.907227 9.38867"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M0.907227 1.38867L8.90723 9.38867"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
