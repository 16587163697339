<script lang="ts">
  import { slide } from "svelte/transition";
  import Warning from "../icons/Warning.svelte";

  //Props
  export let title = "The error occurred while processing your request.";
  export let text = "Please try again";
</script>

<div class="error" in:slide={{ duration: 100 }}>
  <div class="info">
    <Warning />
    <h4><span>{title}</span> {text}</h4>
  </div>
</div>

<style>
  .error {
    margin: 1rem 0;
    width: 100%;
    border: 1px solid var(--color-red);
    border-radius: var(--radius-medium);
    overflow: hidden;
  }

  .info {
    padding: 0.25rem 2rem;
    font-weight: 400;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }

  .info h4 {
    color: var(--color-black);
    font-weight: 400;
    margin-left: 0.5rem;
  }

  .info h4 span {
    font-weight: 600;
  }
</style>
