<script lang="ts">
  //Props
  export let isLoading = false;
</script>

<span class="loader {!isLoading ? 'hidden' : ''}" />

<style>
  .loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }
  .hidden {
    opacity: 0;
  }
  .loader::after,
  .loader::before {
    content: "";
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }
  .loader::after {
    animation-delay: 1s;
  }

  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
</style>
