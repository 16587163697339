<svg
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.5947 0C5.97073 0 0.594727 5.376 0.594727 12C0.594727 18.624 5.97073 24 12.5947 24C19.2187 24 24.5947 18.624 24.5947 12C24.5947 5.376 19.2187 0 12.5947 0ZM13.7936 17.9977H11.3936V15.5977H13.7936V17.9977ZM13.7936 13.2H11.3936V6H13.7936V13.2Z"
    fill="#DA5847"
  />
</svg>
