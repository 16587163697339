<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import Clear from "../icons/Clear.svelte";
  import type { TrackingCodeInput } from "../models/local";
  import { fly } from "svelte/transition";

  const dispatch = createEventDispatcher();

  //Props
  export let inputCodes: TrackingCodeInput[];
  export let codesInputValue: string;

  //State
  let codeInput: HTMLInputElement;
  let allSelected = false;

  onMount(async () => {
    codeInput.focus();
  });

  const getLastCode = (): string => {
    if (inputCodes.length) {
      return inputCodes[inputCodes.length - 1].code;
    }
    return "";
  };

  const onInputChange = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === ("a" || "A")) {
      allSelected = !allSelected;
    } else if (event.key === "Backspace" && codesInputValue === "") {
      dispatch("removeCode", {
        codeToRemove: getLastCode(),
        removeAll: allSelected,
      });
      allSelected = false;
    } else if (event.key === "Enter") {
      dispatch("emitEnterPress");
    }
  };

  const onCodeRemove = (codeToRemove: string) => {
    dispatch("removeCode", { codeToRemove, removeAll: allSelected });
    allSelected = false;
  };
</script>

<div
  id="codes-input"
  on:click={(_) => codeInput.focus()}
  on:keydown={(_) => codeInput.focus()}
>
  {#each inputCodes as code (code.code)}
    <div
      class="code {allSelected ? 'highlighted' : ''}  {code.status ===
      'NOT_FOUND'
        ? 'not-found'
        : ''}"
      out:fly={{ x: -10 }}
    >
      {code.code}
      <button class="clear-code" on:click={(_) => onCodeRemove(code.code)}
        ><Clear /></button
      >
    </div>
  {/each}

  <input
    bind:this={codeInput}
    bind:value={codesInputValue}
    on:keyup={onInputChange}
    autocomplete="false"
    type="text"
    id="code-input"
    size={codesInputValue.length + 3}
  />
  {#if inputCodes.length}
    <div class="clear-all">
      <button
        class="clear-all-button"
        on:click={() => {
          dispatch("removeCode", { codeToRemove: "", removeAll: true });
        }}>Clear all</button
      >
    </div>
  {/if}
</div>

<style>
  #codes-input {
    position: relative;
    text-align: left;
    margin-bottom: 0.5rem;
    border-radius: var(--radius-medium);
    border: 1px solid var(--color-cypress);
    padding: 0.75rem 5.5rem 0.5rem 0.5rem;
  }

  #codes-input::after {
    content: " ";
    display: block;
    clear: both;
  }

  .code {
    float: left;
    position: relative;
    border: none;
    border-radius: var(--radius-small);
    background-color: var(--color-cypress);
    color: var(--color-white);
    margin: 0 0.25rem 0.25rem 0;
    padding: 0.125rem 1.5rem 0.125rem 0.375rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    transition: all 0.5s;
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
    box-sizing: border-box;
  }

  .highlighted {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 10px 0px rgb(0 0 0 / 50%),
      0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .clear-code {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.375rem 0.375rem 0.375rem 0.5rem;
    background: linear-gradient(
      90deg,
      var(--color-transparent) 0%,
      var(--color-cypress) 20%,
      var(--color-cypress) 100%
    );
  }

  .not-found,
  .not-found .clear-code {
    background: var(--color-red);
  }

  #code-input {
    float: left;
    vertical-align: top;
    border: none;
    outline: none;
    margin: 0 0.25rem 0.25rem 0;
    padding: 0.375rem;
    font-size: 1rem;
    height: 1rem;
    box-sizing: content-box;
    max-width: 100%;
  }

  .clear-all {
    position: absolute;
    top: 0;
    right: 1rem;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  .clear-all-button {
    border-bottom: 1px solid var(--color-cypress);
    color: var(--color-cypress);
  }
</style>
