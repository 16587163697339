<script lang="ts">
  import { slide } from "svelte/transition";

  import Arrow from "../../icons/Arrow.svelte";
  import Tooltip from "../Tooltip.svelte";

  import type { CarriersEnum, Tracking } from "../../models/tracking";
  import {
    capitalizeAllWords,
    getCarrierLink,
    getStatus,
    noDataString,
  } from "../../utils/format";

  //Props
  export let result: Tracking;
  export let isExpanded = false;
  export let index: number;

  const getLastStatus = () => {
    if (result.events.length) {
      return getStatus(
        result.events[0].event_type,
        result.carrier as CarriersEnum
      );
    }

    return noDataString;
  };

  const getLocation = (city?: string, state?: string) => {
    if (city && state) {
      return `${capitalizeAllWords(city)}, ${state}`;
    }

    if (city || state) {
      return state || capitalizeAllWords(city);
    }

    return "";
  };
</script>

<div class="tracking-result" in:slide={{ duration: 100, delay: index * 100 }}>
  <div class="header">
    <span>Tracking number</span>
    {result.tracking_number}
  </div>
  <div class="info">
    <div class="row">
      <div class="column">
        <h4>Carrier</h4>
        <p>
          {#if result.carrier}
            <Tooltip title="Get detailed shipment information"
              ><a
                target="_blank"
                rel="noreferrer"
                href={getCarrierLink(result.carrier, result.tracking_number)}
                >{result.carrier} <Arrow /></a
              ></Tooltip
            > &nbsp;
          {:else}
            {noDataString}
          {/if}
        </p>
      </div>
      <div class="column">
        <h4>Location</h4>
        <p>
          {getLocation(
            result.origin_station_city,
            result.origin_station_state
          ) || noDataString}
        </p>
      </div>
      <div class="column">
        <h4>Destination ZIP Code</h4>
        <p>{result.zipcode || noDataString}</p>
      </div>
      <div class="column">
        <h4>Status</h4>
        <p>
          {getLastStatus()}
        </p>
      </div>
      <div class="column">
        <h4>Package History</h4>
        <p>
          <button
            class="toggle-button"
            on:click={() => {
              isExpanded = !isExpanded;
            }}>{isExpanded ? "Hide" : "Show"}</button
          >
        </p>
      </div>
    </div>
  </div>

  {#if isExpanded}
    <div
      class="package-history"
      data-testid="package-history-heading"
      transition:slide
    >
      <h5>Package History</h5>
      <ul class="events">
        {#each result.events as event, index}
          <li>
            <p>
              {getStatus(event.event_type, result.carrier)}{getLocation(
                event.station_city,
                event.station_state
              )
                ? ", " + getLocation(event.station_city, event.station_state)
                : ""}{#if index === 0}
                <Tooltip
                  title="Get detailed shipment information"
                  margin="0 0 0 0.25rem"
                  ><a
                    target="_blank"
                    rel="noreferrer"
                    href={getCarrierLink(
                      result.carrier,
                      result.tracking_number
                    )}
                  >
                    See details <Arrow /></a
                  ></Tooltip
                >
              {/if}
            </p>
            <div class="timestamp">
              {new Date(event.timestamp).toLocaleTimeString()} on {new Date(
                event.timestamp
              ).toLocaleDateString()}
            </div>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</div>

<style>
  .tracking-result {
    margin: 1rem 0;
    width: 100%;
    border: 1px solid var(--color-light-gray);
    border-radius: var(--radius-medium);
  }

  .header {
    background-color: var(--color-light-gray);
    color: var(--color-steel);
    padding: 0.375rem 2rem;
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 600;
  }

  .header span {
    color: var(--color-gray);
    font-weight: 400;
    margin-right: 1rem;
  }

  .info {
    padding: 0.5rem 2rem;
  }

  .info h4 {
    color: var(--color-gray);
    font-weight: 400;
    font-size: 0.875rem;
    margin: 1rem 0 0 0;
    position: relative; /* so tooltip can work on this */
  }

  .info p {
    color: var(--color-steel);
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0.5rem 0 1rem 0;
  }

  .toggle-button {
    color: var(--color-lime);
    border-bottom: 1px solid var(--color-lime);
    font-size: 0.875rem;
    font-weight: 600;
  }

  .package-history {
    padding: 0.5rem 2rem;
    border-top: 1px solid var(--color-light-gray);
  }

  ul,
  li {
    list-style: none;
    padding: 0;
  }

  h5 {
    padding: 0;
    margin: 1rem 0;
    color: var(--color-steel);
    font-size: 1rem;
    font-weight: 600;
  }

  ul {
    margin-left: 1rem;
  }

  li {
    padding-bottom: 1.5rem;
    border-left: 3px solid var(--color-light-gray);
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    font-size: 0.875rem;
  }

  li:last-child {
    border-color: var(--color-white);
    padding-bottom: 0;
  }
  li:first-child:before {
    content: "";
    width: 20px;
    height: 20px;
    background: var(--color-mid-gray);
    border-radius: 50%;
    position: absolute;
    left: -11px;
    top: 0px;
  }
  li:first-child:after {
    content: "";
    width: 8px;
    height: 8px;
    background: var(--color-cypress);
    border-radius: 50%;
    position: absolute;
    left: -5px;
    top: 6px;
  }
  li:before {
    content: "";
    width: 20px;
    height: 20px;
    background: var(--color-cypress);
    border-radius: 50%;
    position: absolute;
    left: -11px;
    top: 0px;
  }

  .events {
    margin-top: 2rem;
    border-radius: 0.75rem;
    position: relative;
  }

  .events p {
    color: var(--color-steel);
    line-height: 1.5;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
  }
  .events .timestamp {
    color: var(--color-gray);
    font-weight: 400;
  }

  @media (max-width: 640px) {
    .header,
    .info {
      padding: 0.5rem 1rem;
    }

    .header span {
      display: block;
    }

    ul {
      margin-left: 0;
    }
  }
</style>
