import { CarriersEnum, TrackingEventEventSourceEnum, } from "../models/tracking";
export const noDataString = "--";
export const capitalizeAllWords = (input) => {
    return input
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
};
export const getStatus = (status, carrier, eventSource) => {
    switch (status) {
        case "ARRIVED":
            return "Package arrived to CloudSort Station";
        case "MANIFESTED_INBOUND":
            return "Package in transit to CloudSort Station";
        case "DISPATCHED":
            return `Package is being transferred from CloudSort to ${carrier}`;
        case "CARRIER_ARRIVED_AT_FACILITY":
            return `Package arrived to ${carrier} Station`;
        case "CARRIER_OUT_FOR_DELIVERY":
            return `Package out for delivery by ${carrier}`;
        case "CARRIER_IN_TRANSIT":
            return `Package in transit ${eventSource === TrackingEventEventSourceEnum.LINEHAUL ? "to" : "with"} ${carrier}`;
        case "CARRIER_DELIVERED":
            return `Package delivered by ${carrier}`;
        case "CARRIER_DELIVERY_FAILED":
            return `Package not delivered by ${carrier}`;
        default:
            return status;
    }
};
export const getCarrierLink = (carrier, trackingNumber) => {
    switch (carrier) {
        case CarriersEnum.USPS:
            return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${trackingNumber}`;
        case CarriersEnum.UDS:
            return `https://www.uniteddeliveryservice.com/track/barcode/${trackingNumber}`;
        case CarriersEnum.CDL:
            return `https://ship.cdldelivers.com/Xcelerator/Tracking/Tracking?packageitemrefno=${trackingNumber}`;
        case CarriersEnum.LSO:
            return `https://www2.lso.com/access/trackingresult.aspx?airbillnos=${trackingNumber}`;
        case CarriersEnum.ONTRAC:
            return `https://www.ontrac.com/tracking.asp?trackingres=submit&tracking_number=${trackingNumber}`;
        default:
            return "";
    }
};
