<script lang="ts">
  import { onMount } from "svelte";

  import type { TrackingCodeInput, TrackingStatus } from "./models/local";
  import type { InlineResponse20025, Tracking } from "./models/tracking";

  import CodesInput from "./components/CodesInput.svelte";
  import CloudSortLogo from "./icons/CloudSortLogo.svelte";
  import TrackingResultNotFound from "./components/results/TrackingResultNotFound.svelte";
  import TrackingResult from "./components/results/TrackingResult.svelte";
  import Loader from "./components/Loader.svelte";

  import {
    setSearchedTrackingNumbers,
    getSearchedTrackingNumbers,
  } from "./utils/localStorage";
  import { getTrackingResults } from "./services/Tracking.service";
  import ErrorAlert from "./components/ErrorAlert.svelte";

  //State
  let inputCodes: TrackingCodeInput[] = [];
  let codesInputValue: string = "";
  let savedTrackingNumbers: string;
  let results: Tracking[] = [];
  let isLoading = false;
  let isError = false;
  let lastUpdated: number;

  //Contants
  const MAX_TRACKING_NUMBERS = 10;

  // Reactive expresions

  $: {
    if (codesInputValue.slice(-1) === ",") {
      addCodes();
    }
  }

  const addCodes = (codeInput = codesInputValue) => {
    const parsedNewCodes = codeInput
      .trim()
      .split(",")
      .filter((code) => code)
      .map((code) => {
        const sanitizedCode = code
          .trim()
          .split("")
          .filter((letter) => letter.match(/\s|\d|[a-zA-Z]|-|_/g)) // only allow letters, numbers, spaces, dashes and underscores
          .join("");

        return {
          code: sanitizedCode.toUpperCase(),
          status: "PENDING",
        } as TrackingCodeInput;
      });

    const allCodes = [...inputCodes, ...parsedNewCodes];

    const uniqueCodes = [
      ...new Set(allCodes.map((inputCode) => inputCode.code)),
    ];

    inputCodes = uniqueCodes
      .map((uniqueCode) => allCodes.find((code) => code.code === uniqueCode))
      .slice(0, MAX_TRACKING_NUMBERS);

    codesInputValue = "";
  };

  const removeCode = (params: {
    codeToRemove: string;
    removeAll?: boolean;
  }) => {
    if (params.removeAll) {
      codesInputValue = "";
      inputCodes = [];
    } else {
      inputCodes = [
        ...inputCodes.filter((code) => code.code !== params.codeToRemove),
      ];
    }
  };

  const onSearchButtonClick = () => {
    addCodes();
    processSearch();
  };

  const processSearch = () => {
    // reset states
    isLoading = true;
    isError = false;
    results = [];
    inputCodes = inputCodes.map((code) => {
      return { ...code, status: "PENDING" as TrackingStatus };
    });

    // perform a request
    getTrackingResults(inputCodes.map((inputCode) => inputCode.code))
      .then((data) => data.json())
      .then((data: InlineResponse20025) => {
        const inputTrackingCodes = inputCodes.map(
          (inputCode) => inputCode.code
        );

        const sorted = data.results.sort((a, b) => {
          //sort by order of input numbers
          let indexA = inputTrackingCodes.indexOf(a.tracking_number!);
          let indexB = inputTrackingCodes.indexOf(b.tracking_number!);
          return indexA - indexB;
        });

        results = sorted;
        lastUpdated = Date.now();

        // map input code statuses
        inputCodes = inputCodes.map((code) => {
          const trackingCode = code.code.replaceAll(" ", "");
          const status = data.results.find((result) => trackingCode.includes(result.tracking_number)).status;
          return {
            ...code,
            status: status as TrackingStatus,
          };
        });

        // update URL
        window.history.replaceState(
          {},
          null,
          inputTrackingCodes.length
            ? `?tracking_numbers=${inputTrackingCodes.join(",")}`
            : "/"
        );

        // save to local storage
        setSearchedTrackingNumbers(inputTrackingCodes.join(","));
      })
      .catch((err) => {
        console.error("Error while processing search results:", err);
        isError = true;
      })
      .finally(() => {
        isLoading = false;
      });
  };

  // Add tracking numbers from url or local storage
  onMount(() => {
    const location = window.location.search.replace("?tracking_numbers=", "");
    if (location) {
      addCodes(location);
      processSearch();
    } else {
      const savedNumbers = getSearchedTrackingNumbers();
      if (savedNumbers) {
        savedTrackingNumbers = savedNumbers;
      }
    }
  });
</script>

<div class="fixed-background full-screen-fixed" />
<div class="fixed-background-overlay full-screen-fixed" />
<header>
  <CloudSortLogo />
</header>
<section class="content-wrapper full-screen-fixed">
  <main>
    <h1>Tracking Information</h1>
    <p>
      Enter up to {MAX_TRACKING_NUMBERS} tracking numbers, separated by commas.
    </p>
    <CodesInput
      {inputCodes}
      bind:codesInputValue
      on:removeCode={(e) => {
        removeCode(e.detail);
      }}
      on:emitEnterPress={() => {
        addCodes(codesInputValue);
        processSearch();
      }}
    />
    {#if inputCodes.length === MAX_TRACKING_NUMBERS}
      <p class="limit-reached-notification">
        You've reached the maximum tracking numbers available to search at once.
      </p>
    {/if}
    <div class="row">
      <div class="column" style="text-align:left">
        {#if savedTrackingNumbers}
          <button
            class="restore-button"
            on:click={() => {
              addCodes(savedTrackingNumbers);
              savedTrackingNumbers = "";
            }}>View your recently tracked packages</button
          >
        {/if}
      </div>
      <div class="column" style="text-align:right">
        <button
          class="search-button"
          type="button"
          disabled={isLoading}
          on:click={onSearchButtonClick}>Search<Loader {isLoading} /></button
        >
      </div>
    </div>
    {#if isError}
      <ErrorAlert />
    {/if}
    {#if results.length}
      <h3>Results:</h3>
    {/if}
    {#each results as result, index (lastUpdated + "" + index)}
      {#if result.status === "FOUND"}
        <TrackingResult {result} {index} isExpanded={results.length === 1} />
      {:else}
        <TrackingResultNotFound
          trackingNumber={result.tracking_number}
          {index}
        />
      {/if}
    {/each}
  </main>
</section>

<style>
  .full-screen-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .fixed-background {
    background-color: var(--color-cypress);
    background-image: url("/images/background-optimized.jpg");
    background-size: cover;
  }

  .fixed-background-overlay {
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(20, 37, 45, 0.5) 0%,
      rgba(54, 52, 55, 5e-5) 100%
    );
  }

  .content-wrapper {
    position: absolute;
    bottom: auto;
    min-height: 100vh;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9rem 2rem 2rem 2rem;
    overflow: auto;
    box-sizing: border-box;
  }

  header {
    z-index: 2;
    position: fixed;
    top: 50px;
    left: 50px;
    text-align: left;
  }

  main {
    padding: 4rem 5rem;
    width: 100%;
    background-color: white;
    max-width: 940px;
    border-radius: var(--radius-large);
  }
  h1 {
    font-size: 2rem;
    margin: 0 0 1rem 0;
    font-weight: 600;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
  }

  p.limit-reached-notification {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .restore-button {
    border-bottom: 1px solid var(--color-cypress);
    color: var(--color-cypress);
    font-weight: 600;
    margin: 0.5rem 0.5rem 2rem 0.5rem;
  }

  .search-button {
    position: relative;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1;
    color: var(--color-white);
    border-radius: var(--radius-small);
    background: var(--color-cypress);
    padding: 1rem 1.75rem;
  }

  .search-button:disabled {
    color: var(--color-cypress);
  }

  @media (max-width: 820px) {
    main {
      padding: 3rem;
    }
  }

  @media (max-width: 640px) {
    .content-wrapper {
      padding: 9rem 1rem 1rem 1rem;
    }

    main {
      padding: 2rem;
    }
    .restore-button {
      font-size: 0.875rem;
    }
  }
</style>
