<svg
  width="9"
  height="10"
  viewBox="0 0 9 10"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1.50749 8.33334L8.17416 1.66667"
    stroke="#5FCE84"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M1.50749 1.66667H8.17416V8.33334"
    stroke="#5FCE84"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
