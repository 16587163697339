<script lang="ts">
  import { slide } from "svelte/transition";
  import Warning from "../../icons/Warning.svelte";

  //Props
  export let trackingNumber: string;
  export let index: number;
</script>

<div class="tracking-result" in:slide={{ duration: 100, delay: index * 100 }}>
  <div class="header">
    <span>Tracking number</span>
    {trackingNumber}
  </div>
  <div class="info">
    <Warning />
    <h4>No results found</h4>
  </div>
</div>

<style>
  .tracking-result {
    margin: 1rem 0;
    width: 100%;
    border: 1px solid var(--color-red);
    border-radius: var(--radius-medium);
    overflow: hidden;
  }

  .header {
    background-color: var(--color-red);
    color: var(--color-white);
    padding: 0.375rem 2rem;
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 600;
  }

  .header span {
    font-weight: 400;
    margin-right: 1rem;
  }

  .info {
    padding: 0.25rem 2rem;
    font-weight: 400;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }

  .info h4 {
    color: var(--color-black);
    font-weight: 600;
    margin-left: 0.5rem;
  }

  @media (max-width: 640px) {
    .header,
    .info {
      padding: 0.5rem 1rem;
    }

    .header span {
      display: block;
    }
  }
</style>
